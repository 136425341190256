<template>
    <v-container class="content-container pt-2 pb-0">
        <v-row align="center">
            <v-col>
                <v-row
                    dense
                    :align="!isMobilePort ? 'center' : 'top'">
                    <v-col cols="auto">
                        <v-avatar
                            :size="56"
                            class="float-left mr-1 mt-1"
                            style="border-radius: 12px;">
                            <slot name="avatar">
                            </slot>
                        </v-avatar>
                    </v-col>
                    <v-col>
                        <h1
                            style="line-height: 1.2"
                            v-html="title">
                        </h1>
                        <p
                            class="mb-0 grey--text text--darken-1"
                            v-html="subTitle">
                        </p>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!quickAskEnabled"
                    class="mt-2">
                    <v-col>
                        <template v-if="!hasPremiumSubscription">
                            <div
                                class="primaryLight"
                                style="width: auto; border-radius: 4px 0 0 4px; display: inline-block">
                                <v-icon
                                    color="primary"
                                    left
                                    class="ml-2">
                                    mdi-lock-outline
                                </v-icon>
                                <span class="caption primary--text font-weight-bold mt-1 mr-2">
                                    PREMIUM REQUIRED
                                </span>
                                <v-btn
                                    small
                                    :x-small="isMobilePort"
                                    color="accent2"
                                    elevation="0"
                                    @click="toggleExpand()">
                                    SUBSCRIBE TO PREMIUM
                                    <v-icon right>
                                        {{expandActive ? 'mdi-menu-up' : 'mdi-menu-down'}}
                                    </v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <v-btn
                            v-else
                            small
                            color="accent2"
                            elevation="0"
                            @click="toggleExpand()">
                            <v-icon left>
                                mdi-creation
                            </v-icon>
                            PREMIUM ACTIVE
                            <v-icon right>
                                {{ expandActive ? 'mdi-menu-up' : 'mdi-menu-down'}}
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <slot name="tabs">
        </slot>
    </v-container>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    import AskQuestionButton from "../buttons/AskQuestionButton.vue";
    import {AppParameters} from "../../AppParameters";

    export default {
        name: 'PremiumToolHeader',
        components: {AskQuestionButton},
        data() {
            return {
                expandActive: false,
                quickAskEnabled: AppParameters.quick_consult_enabled
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            title: {
                required: false,
                type: String
            },
            subTitle: {
                required: false,
                type: String
            }
        },
        methods: {
            toggleExpand() {
                this.expandActive = !this.expandActive
                EventBus.fire('toggle-access-expand')
            }
        }
    }
</script>