<template>
    <v-card outlined>

        <loading class="my-12" v-if="isLoading"></loading>

        <template v-else>
            <v-card-text class="py-0">
                <v-row align="center">
                    <v-col>
                        <a
                            class="fs-16"
                            :href="getRoute('quick_consult')">
                            <v-icon
                                color="primary"
                                class="mt-n1"
                                left>
                                mdi-arrow-left
                            </v-icon>
                            Back to Quick Consult
                        </a>
                    </v-col>
                    <v-col
                        v-if="question?.status"
                        cols="auto">
                        <status-chips
                            :status="question.status">
                        </status-chips>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider>
            </v-divider>
            <v-card-text>
                <v-row dense>
                    <v-col>
                        <v-row
                            dense
                            align="center">
                            <v-col>
                                <h2>{{ question?.title }}</h2>
                            </v-col>
                            <v-col
                                v-if="isAnswered"
                                cols="auto">
                                <v-btn
                                    :loading="isDownloading"
                                    color="primary"
                                    @click="downloadAnswerPdf">
                                    <v-icon class="pr-1">
                                        mdi-download-outline
                                    </v-icon>
                                    Download Answer [PDF]
                                </v-btn>
                            </v-col>
                        </v-row>
                        <p class="text-caption text--secondary">Submitted {{ formatDate(question?.createdAt) }} | {{question?.key}}</p>
                        <p class="fs-16" v-html="question?.description"></p>
                        <div v-if="question?.attachments.length" class="mb-5">
                            <h3 class="mb-2">Attachments</h3>

                            <a :href="formatAttachmentUrl(attachment.id)" v-for="attachment in question.attachments"
                               :key="attachment.id"
                               target="_blank"
                               class="text-decoration-none mr-3">
                                {{ attachment.name }}
                            </a>
                        </div>
                        <div
                            v-if="question?.codeTitle"
                            :class="question.codeSection ? 'mb-4' : ''">
                            <h3 class="mb-2">Code Title</h3>
                            <p class="mb-1" v-html="question.codeTitle"></p>
                        </div>
                        <div v-if="question?.codeSection">
                            <h3 class="mb-2">Code Section</h3>
                            <p class="mb-1" v-html="question.codeSection"></p>
                        </div>
                        <div
                            v-if="isAnswered"
                            class="w-full mt-5 px-3"
                            style="border-left: 4px solid #ffa201">
                            <div class="d-flex align-items-center mb-3">
                                <h3 class="mb-0 mr-3 fs-20">Answer from ICC Team</h3>
                            </div>
                            <div>
                                <div class="fs-16" v-html="question.answer"></div>
                                <div v-if="questionWebLinks.length !== 0" class="my-4">
                                    <h3 class="pb-2">References</h3>
                                    <div
                                        v-for="item in questionWebLinks"
                                        :key="item.id">
                                        <a
                                            :href="item.object.url"
                                            target="_blank">
                                            {{item.object.title}}
                                        </a>
                                    </div>
                                </div>
                                <div class="disclaimer caption text--secondary" style="line-height: 18px;">
                                    <p class="mb-1">
                                        <span class="font-weight-bold">Disclaimer</span>
                                    </p>
                                    <p>
                                        Code opinions issued by International Code Council (“ICC”) staff as part of its Quick Consult Service or otherwise are based on ICC-published codes and do not include or consider local, state or federal codes, policies or amendments. This opinion is based on the information which you have provided to ICC. We have made no independent effort to verify the accuracy of this information nor have we conducted a review beyond the scope of your question. This opinion does not imply approval of an equivalency, specific product, specific design, or specific installation and cannot be published in any form implying such approval by ICC. As this opinion is only advisory, the final decision is the responsibility of the designated authority charged with the administration and enforcement of the applicable code.
                                    </p>
                                    <p>
                                        ICC will make reasonable efforts to provide accurate information as part of any code opinion.  However, ICC makes no guarantees or warranties, express or implied, as to the accuracy of any information provided, including, without limitation, any warranties of merchantability or fitness for a particular purpose.  ICC will not be held liable for any damages or loss, whether direct, indirect, consequential, or punitive, that may arise through your use of any code opinion.
                                    </p>
                                </div>
                                <div class="mt-5">
                                    <v-btn
                                        :loading="isDownloading"
                                        color="primary"
                                        @click="downloadAnswerPdf">
                                        <v-icon class="pr-1">
                                            mdi-download-outline
                                        </v-icon>
                                        Download Answer [PDF]
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <comments-wrapper
                    v-if="question"
                    :question-id="question.id"
                    :comments="question.comments"
                    :can-comment="question.canComment">
                </comments-wrapper>
            </v-card-text>
        </template>
    </v-card>
</template>

<script>
import { QuickConsultApi } from "../../../api/QuickConsultApi";
import Loading from "../../content/partials/loading.vue";
import {DateUtil} from "../../../util/DateUtil";
import CommentsWrapper from "./CommentsWrapper.vue";
import StatusChips from "./StatusChips.vue";

export default {
    components: {StatusChips, CommentsWrapper, Loading},
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            question: null,
            isDownloading: false,
            questionWebLinks: []
        }
    },
    computed: {
        isAnswered() {
            return this.question?.answer && this.question?.status.text === 'Answered';
        }
    },
    methods: {
        async loadQuestion(id) {
            this.isLoading = true;

            await QuickConsultApi.getQuestionDetails(id).then((res) => {
                this.question = res;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        loadQuestionWebLinks(id) {
            QuickConsultApi.getQuestionWebLinks(id).then((res) => {
                this.questionWebLinks = res;
            })
        },
        formatDate(date) {
            return date ? DateUtil.timeToMdy(date) : '';
        },
        formatAttachmentUrl(attachmentId) {
            const questionId = this.question?.id;
            return Routing.generate('question_attachments', {questionId: questionId, attachmentId: attachmentId});
        },
        downloadAnswerPdf() {
            this.isDownloading = true;
            const questionId = this.question?.id;
            QuickConsultApi.getAttachment(questionId, this.question.answerAttachmentInfo?.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.question.answerAttachmentInfo?.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).finally(() => {
                this.isDownloading = false;
            });
        }
    },
    mounted() {
        this.loadQuestion(this.id).then(()=>{
            if (this.question?.status.text === 'Answered') { // only answered qustion can see references
                this.loadQuestionWebLinks(this.id);
            }
        })
    }
}
</script>