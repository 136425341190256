var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hidePromoBanner
    ? _c(
        "v-carousel",
        {
          staticClass: "mx-auto",
          class: _vm.additionalClasses,
          style: `max-width: ${_vm.maxWidth + 160}px`,
          attrs: { height: null, "hide-delimiter-background": "" },
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        _vm._l(_vm.randomizedSlides, function (item, key) {
          return item.active
            ? _c(
                "v-carousel-item",
                { key: key },
                [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "px-12 roboto mx-auto",
                      class: {
                        "revit-promo-banner-mobile py-6": !_vm.isLargeAndUp,
                      },
                      style: { "min-height: 430px": _vm.isMobilePort },
                      attrs: {
                        color: item.bgColor,
                        elevation: "0",
                        rounded: "",
                        "max-width": _vm.maxWidth,
                      },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: { right: "80px" },
                                            attrs: {
                                              fab: "",
                                              small: "",
                                              icon: "",
                                              absolute: "",
                                              color:
                                                item.textColor &&
                                                item.textColor,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.dismissPromoBanners()
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.dismissible
                                          ? _c("v-icon", [
                                              _vm._v(
                                                "\n                            mdi-close\n                        "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                    Dismiss banners\n                "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          style: `min-height: ${
                            _vm.isMobilePort ? 460 : 160
                          }px;`,
                          attrs: { align: "center" },
                        },
                        [
                          item.image
                            ? _c(
                                "v-col",
                                {
                                  class: _vm.isMobilePort ? "text-center" : "",
                                  attrs: { cols: "12", sm: "auto" },
                                },
                                [
                                  _c("img", {
                                    staticClass: "mt-2",
                                    class:
                                      item.image.bgColor && item.image.bgColor,
                                    staticStyle: { "max-width": "200px" },
                                    style: _vm.isMobilePort
                                      ? "max-height: 72px;"
                                      : "max-height: 120px;",
                                    attrs: {
                                      height: item.image.height
                                        ? item.image.height
                                        : "",
                                      src: item.image.url,
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "white--text" },
                            [
                              _c(
                                "h2",
                                {
                                  style:
                                    item.textColor &&
                                    `color: ${item.textColor}`,
                                  domProps: { innerHTML: _vm._s(item.title) },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(item.title) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "fs-18",
                                  class: { "mb-2": _vm.isMobilePort },
                                  style:
                                    item.textColor &&
                                    `color: ${item.textColor}`,
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(item.subTitle) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.isMobilePort && !_vm.isLargeAndUp
                                ? _c(
                                    "v-btn",
                                    {
                                      class: item.button.textColor
                                        ? item.button.textColor
                                        : "accent--text",
                                      attrs: {
                                        color: item.button.color,
                                        "x-large": _vm.isLargeAndUp,
                                        href: item.button.href,
                                        block: _vm.isMobilePort,
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "px-10" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(item.button.text) +
                                            "\n                        "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isMobilePort || _vm.isLargeAndUp
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: item.button.textColor
                                        ? item.button.textColor
                                        : "accent--text",
                                      attrs: {
                                        color: item.button.color,
                                        "x-large": _vm.isLargeAndUp,
                                        large: _vm.isMobilePort,
                                        href: item.button.href,
                                        block: _vm.isMobilePort,
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "px-10" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(item.button.text) +
                                            "\n                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e()
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }