<template>
    <v-carousel
        v-if="!hidePromoBanner"
        v-model="model"
        :height="null"
        class="mx-auto"
        :style="`max-width: ${maxWidth+160}px`"
        hide-delimiter-background
        :class="additionalClasses">
        <v-carousel-item
            v-for="(item, key) in randomizedSlides"
            :key="key"
            v-if="item.active">
            <v-sheet
                class="px-12 roboto mx-auto"
                :class="{'revit-promo-banner-mobile py-6': !isLargeAndUp}"
                :style="{'min-height: 430px': isMobilePort}"
                :color="item.bgColor"
                elevation="0"
                rounded
                :max-width="maxWidth">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            small
                            icon
                            absolute
                            :color="item.textColor && item.textColor"
                            style="right: 80px;"
                            v-bind="attrs"
                            v-on="on"
                            @click="dismissPromoBanners()">
                            <v-icon v-if="dismissible">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Dismiss banners
                    </span>
                </v-tooltip>
                <v-row
                    align="center"
                    :style="`min-height: ${isMobilePort ? 460 : 160}px;`">
                    <v-col
                        v-if="item.image"
                        cols="12"
                        sm="auto"
                        :class="isMobilePort ? 'text-center' : ''">
                        <img
                            class="mt-2"
                            :height="item.image.height ? item.image.height : ''"
                            :class="item.image.bgColor && item.image.bgColor"
                            :src="item.image.url"
                            style="max-width: 200px;"
                            alt=""
                            :style="isMobilePort ? 'max-height: 72px;' : 'max-height: 120px;'"/>
                    </v-col>
                    <v-col
                        class="white--text">
                        <h2
                            v-html="item.title"
                            :style="item.textColor && `color: ${item.textColor}`">
                            {{item.title}}
                        </h2>
                        <p
                            class="fs-18"
                            :class="{'mb-2': isMobilePort}"
                            :style="item.textColor && `color: ${item.textColor}`">
                            {{ item.subTitle }}
                        </p>
                        <v-btn
                            v-if="!isMobilePort && !isLargeAndUp"
                            :color="item.button.color"
                            :class="item.button.textColor ? item.button.textColor : 'accent--text'"
                            :x-large="isLargeAndUp"
                            :href="item.button.href"
                            :block="isMobilePort">
                            <span class="px-10">
                                {{ item.button.text }}
                            </span>
                        </v-btn>
                    </v-col>
                    <v-col
                        v-if="isMobilePort || isLargeAndUp"
                        cols="12"
                        sm="auto">
                        <v-btn
                            :color="item.button.color"
                            :class="item.button.textColor ? item.button.textColor : 'accent--text'"
                            :x-large="isLargeAndUp"
                            :large="isMobilePort"
                            :href="item.button.href"
                            :block="isMobilePort">
                            <span class="px-10">
                                {{ item.button.text }}
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    import {AppParameters} from "../../AppParameters";
    import {ArrayUtils} from "../../util/ArrayUtils";

    export default {
        name: 'CarouselBanner',
        data() {
            return {
                model: 0,
                hidePromoBanner: this.dismissible && this.$session.has('hidePromoBanners'),
                banners: [
                    {
                        active: AppParameters.ai_navigator_enabled,
                        bgColor: '#e2eae7',
                        textColor: '#0b5940',
                        image: {
                            url: this.asset(require('../../images/banners/ai_navigator.png'))
                        },
                        title: 'Understand codes faster with AI Navigator',
                        subTitle: 'The power of AI, trained by ICC experts',
                        button: {
                            color: 'primary',
                            textColor: 'white--text',
                            text: 'Learn More',
                            href: this.getRoute('features_ai_navigator')
                        }
                    },
                    {
                        active: AppParameters.enable_premium_answers_page,
                        bgColor: '#e2eae7',
                        textColor: '#0b5940',
                        image: {
                            url: this.asset(require('../../images/banners/premium_answers.png'))
                        },
                        title: 'Real questions answered by our team of experts',
                        subTitle: 'Carefully crafted interpretations to popular compliance questions',
                        button: {
                            color: 'primary',
                            textColor: 'white--text',
                            text: 'Access Q&A Database',
                            href: this.getRoute('premium_answers')
                        }
                    },
                    {
                        active: true,
                        bgColor: '#186bfd',
                        image: {
                            url: this.asset(require('../../images/banners/2024_codes.jpg'))
                        },
                        title: '2024 I-Codes Now Available',
                        subTitle: 'Latest cycle of International Codes available on Digital Codes',
                        button: {
                            color: 'white',
                            text: 'Browse Available Titles',
                            href: this.getRoute('codes_category', {
                                'category': '2024-icodes'
                            })
                        }
                    },
                    {
                        active: true,
                        bgColor: '#186BFD',
                        image: {
                            url: this.asset(require('../../images/dcp_logos/120x120_FINAL_WHT_transp_bkgd.webp')),
                            bgColor: 'white'
                        },
                        title: 'ICC Digital Codes Premium Revit<sup>®</sup> Add-In',
                        subTitle: 'Access and reference building codes and standards directly within your BIM application',
                        button: {
                            color: 'white',
                            text: 'Get Add-in',
                            href: this.getRoute('revit')
                        }
                    },
                    {
                        active: true,
                        bgColor: 'grey lighten-3',
                        textColor: '#0b5940',
                        image: {
                            url: this.asset(require('../../images/banners/HMEx_Banner_AD_FINAL.jpg')),
                        },
                        title: 'Classify. Coordinate. Comply.',
                        subTitle: 'Ensure fire and building code compliance. Manage your hazmat inventory with ease.',
                        button: {
                            text: 'Try it for free today',
                            color: 'cyan lighten-2',
                            textColor: 'white',
                            href: 'https://hmexassistant.com/product/free-trial/'
                        }
                    },
                    {
                        active: AppParameters.compliance_calculators_enabled,
                        bgColor: 'grey lighten-3',
                        textColor: '#0b5940',
                        image: {
                            url: this.asset(require('../../images/calculators/calculator_placeholder.svg')),
                            height: 90
                        },
                        title: 'Code Compliance Calculators',
                        subTitle: 'Streamline compliance checks with in-app dynamic computations',
                        button: {
                            color: 'primary',
                            textColor: 'white--text',
                            text: 'Learn More',
                            href: this.getRoute('compliance_calculators')
                        }
                    }
                ]
            }
        },
        computed: {
            randomizedSlides() {
                return ArrayUtils.shuffle(this.banners);
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            dismissible: {
                required: false,
                type: Boolean,
                default: false
            },
            additionalClasses: {
                required: false,
                type: String,
                default: ''
            },
            maxWidth: {
                required: false,
                type: Number,
                default: 1360
            }
        },
        methods: {
            dismissPromoBanners() {
                this.$session.set('hidePromoBanners', true)
                this.hidePromoBanner = true
            }
        }
    }
</script>
