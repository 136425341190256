<template>
    <div
        ref="sticky"
        id="sticky-chapter-info"
        class="sticky-chapter white"
        :style="titleContainerStyle">
        <v-divider
            v-if="showExam">
        </v-divider>
        <v-container
            fluid
            class="pb-0 pt-1">
            <v-row v-if="isMobilePort" dense>

                <v-col cols="auto">
                    <v-btn
                            fab
                            x-small
                            color="primary"
                            @click="toggleToc(true)">
                        <v-icon>
                            mdi-menu-close
                        </v-icon>
                    </v-btn>
                </v-col>

                <v-col>
                    <span class="caption">{{ getBookTitle(documentInfo) }}</span>
                    <h4 class="mt-1" v-html="getChapterTitle"></h4>
                </v-col>

                <template v-if="!showExam">

                    <v-col cols="auto">
                        <premium-status
                            v-if="!documentInfo.is_astm"
                            displayRightAligned
                            displayIconOnly
                            :documentInfo="documentInfo"
                            :contentPremium="contentPremium"
                            :contentProtection="contentProtection"
                            :contentBasic="contentBasic">
                        </premium-status>
                    </v-col>

                </template>

            </v-row>
            <v-row
                v-else
                align="center"
                dense>
                <v-col
                    cols="auto">
                    <div
                        v-if="!tocMini"
                        class="ml-n7">
                        <v-tooltip
                            max-width="200"
                            right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    fab
                                    x-small
                                    v-on="on"
                                    color="white"
                                    class="elevation-1"
                                    role="button"
                                    aria-label="Side Menu Toggle Icon"
                                    @click="tocMini = true">
                                    <v-icon>
                                        mdi-menu-open
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Click to toggle Table of Contents.</span>
                        </v-tooltip>
                    </div>
                    <v-tooltip
                        v-else
                        max-width="200"
                        right>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                x-small
                                v-on="on"
                                color="primary"
                                @click="tocMini = false">
                                <v-icon>
                                    mdi-menu-close
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Click to toggle Table of Contents.</span>
                    </v-tooltip>
                </v-col>
                <v-col>
                    <titles-breadcrumb
                        v-if="!showExam"
                        class="mt-n1"
                        :document-info="documentInfo">
                    </titles-breadcrumb>
                    <h3
                        class="mt-1"
                        v-html="getChapterTitle">
                    </h3>
                </v-col>
                <v-col cols="auto">
                    <template v-if="!showExam">
                        <premium-status
                            v-if="!documentInfo.is_astm"
                            displayRightAligned
                            :documentInfo="documentInfo"
                            :contentPremium="contentPremium"
                            :contentProtection="contentProtection"
                            :contentBasic="contentBasic">
                        </premium-status>
                        <p
                            v-else
                            class="mb-0 mt-1 caption font-weight-bold">
                            ASTM Standards are presented in read-only format.
                        </p>
                    </template>
                    <div class="text-right mt-1">
                        <v-row
                            dense
                            align="center"
                            justify="end">
                            <v-col v-if="isCommentary && showCommentaryButton">
                                <titles-commentary-button>
                                </titles-commentary-button>
                            </v-col>
                            <v-col cols="auto">
                                <full-screen-button>
                                </full-screen-button>
                            </v-col>
                            <v-col
                                cols="auto">
                                <legend-button
                                    v-if="documentInfo.document_id && !showExam"
                                    :document-id="documentInfo.document_id">
                                </legend-button>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-divider></v-divider>

        <div class="white">
            <newer-version-available-alert
                    class="ma-2"
                    :visible="!isLatestVersion && !showExam"
                    :master-document-id="currentDocumentMasterId">
            </newer-version-available-alert>

            <return-last-position-message
                    v-if="isOnLandingPage && documentInfo?.id && !showExam"
                    :document-info-id="documentInfo.id"
                    class="ma-2">
            </return-last-position-message>

            <prior-version-note-migrate-alert class="ma-2"></prior-version-note-migrate-alert>
            <premium-information-modal v-if="!showExam"></premium-information-modal>
        </div>

    </div>
</template>

<script>
    import PremiumStatus from "./PremiumStatus.vue";
    import TitlesBreadcrumb from "./Breadcrumb.vue";
    import TitlesCommentaryButton from "./CommentaryButton.vue";
    import LegendButton from "./LegendButton.vue";
    import AccessLevelPreview from "../partials/AccessLevelPreview.vue";
    import PremiumInformationModal from "./PremiumInformationModal.vue";
    import NewerVersionAvailableAlert from "../../alerts/NewerVersionAvailableAlert.vue";
    import PriorVersionNoteMigrateAlert from "../../alerts/PriorVersionNoteMigrateAlert.vue";
    import Mobile from "../../inc/mixins/mobile";
    import FullScreenButton from "./FullScreenButton.vue";
    import ReturnLastPositionMessage from "../../ReturnLastPositionMessage.vue";
    import {AsyncUtils} from "../../../util/AsyncUtils";

    export default {
        name: "ChapterHeader",
        components: {
            FullScreenButton,
            ReturnLastPositionMessage,
            PriorVersionNoteMigrateAlert,
            NewerVersionAvailableAlert,
            PremiumInformationModal,
            AccessLevelPreview, LegendButton, TitlesBreadcrumb, TitlesCommentaryButton, PremiumStatus},
        data() {
            return {
                chapterTitle: '',
                associatedTitles: [],
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            showExam : {
                required: false,
                type: Boolean
            },
            showCommentaryButton: {
                required: false,
                type: Boolean
            },
            contentPremium: {
                required: false,
                type: Boolean|String
            },
            contentProtection: {
                required: false,
                type: Boolean|String
            },
            contentBasic: {
                required: false,
                type: Boolean|String
            }
        },
        computed: {
            getChapterTitle() {
                return this.chapterTitle
            },
            titleContainerStyle(){
                let stickyCss = '';
                let leftPosition = 0;
                if (!this.tocMini && !this.isMobilePort) {
                    leftPosition = `${this.tocWidth(!this.documentInfo.is_xml)}px`;
                }
                stickyCss = `position: fixed;z-index: 199;width: auto;right: 0px;left: ${leftPosition};`;
                return stickyCss;
            },
            isCommentary() {
                return this.isActiveUser && (this.documentInfo.book_categories || []).indexOf('Commentaries') > -1;
            },
            currentDocumentMasterId() {
                return this.documentInfo.master_document_id || this.documentInfo.document_id || 0;
            },
            isLatestVersion() {

                if (this.documentInfo) {
                    return this.documentInfo.latest_version_id == this.documentInfo.id;
                }

                return true;
            },
            isOnLandingPage() {
                return this.$store.getters.getIsLanding;
            }
        },
        methods: {
            setHeight(){
                const elements = ['contentParent', 'contentOverview']
                elements.forEach((element) => {
                    let container = document.getElementById(element);
                    if (container) {
                        container.style.marginTop = this.$refs.sticky.clientHeight + 'px';
                    }
                })
            },
            toggleToc(isVisible) {
                EventBus.fire('toggle-mobile-legend', !!isVisible);
            }
        },
        mounted() {
            EventBus.listen('update-chapter-title', (chapterTitle) => this.chapterTitle = chapterTitle);

            AsyncUtils.setIntervalOnce(() => {
                this.setHeight();
            }, 200, "setHeight");
        }
    }
</script>
