var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("hero-banner-new", {
        staticClass: "content-container",
        attrs: {
          title: "Go digital, work smarter with Digital Codes Premium",
          subtitle:
            "Streamline code compliance with enhanced tools and exclusive features",
        },
        scopedSlots: _vm._u([
          {
            key: "button",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "font-weight-bold",
                    class: { "mt-4": _vm.isMobilePort },
                    attrs: {
                      block: _vm.isMobilePort,
                      elevation: "0",
                      large: "",
                      color: "accent2",
                      href: "#priceBlock",
                    },
                  },
                  [_vm._v("\n                Subscribe\n            ")]
                ),
                _vm._v(" "),
                _c("start-trial-button", {
                  staticClass: "mr-3",
                  attrs: {
                    block: _vm.isMobilePort,
                    outlined: "",
                    color: "primary",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "image",
            fn: function () {
              return [
                _c("img", {
                  staticStyle: { "max-width": "100%" },
                  attrs: {
                    alt: "",
                    src: _vm.asset(
                      require("../../images/pc_computer.webp"),
                      "default"
                    ),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container pt-8" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.icons, function (item, key) {
                  return !_vm.isMobilePort || (_vm.isMobilePort && key <= 2)
                    ? _c("v-col", { key: key, attrs: { cols: "auto" } }, [
                        _c("img", {
                          attrs: {
                            width: "72px",
                            alt: "",
                            src: _vm.asset(
                              require(`../../images/icons/titles/${item}`),
                              "default"
                            ),
                          },
                        }),
                      ])
                    : _vm._e()
                }),
                1
              ),
              _vm._v(" "),
              _c("sub-main-heading", {
                attrs: {
                  "sub-heading": "Largest and most trusted online database",
                  "main-heading":
                    "Building codes, standards and more exclusive ICC content",
                },
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mb-6" },
                _vm._l(_vm.cards, function (item, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      attrs: {
                        cols: "12",
                        sm: "4",
                        "offset-sm": key % 2 === 0 ? 2 : 0,
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { elevation: "5" } },
                        [
                          _c("v-card-text", [
                            _c("p", {
                              staticClass: "mb-0 text-center fs-16",
                              domProps: { innerHTML: _vm._s(item) },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container mt-16 mb-10" },
        [
          _c("sub-main-heading", {
            attrs: {
              "sub-heading": "Unlock compliance research benefits",
              "main-heading": "Premium tools to ensure you understand the code",
            },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { order: 1, "order-sm": 0, cols: "12", sm: "6" } },
                _vm._l(_vm.premiumTools, function (item, key) {
                  return _c(
                    "v-row",
                    { key: key, attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { attrs: { color: "accent2" } }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.icon) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("p", { staticClass: "font-weight-medium mb-1" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.title) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticStyle: { "line-height": "20px" } }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.description) +
                              "\n                        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("v-col", [
                _c("img", {
                  staticStyle: { "max-width": "100%" },
                  attrs: {
                    alt: "",
                    src: _vm.asset(
                      require(`../../images/premium_page/premium_tools.webp`),
                      "default"
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.heroBlocks, function (item, key) {
        return [
          item.active
            ? _c(
                "div",
                { key: key, class: key % 2 === 0 ? "grey lighten-5" : "" },
                [
                  _c(
                    "v-container",
                    { staticClass: "content-container py-8" },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "6",
                                order: 1,
                                "order-sm": key % 2 === 0 ? 1 : 0,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mb-4",
                                attrs: {
                                  alt: "",
                                  src: _vm.asset(
                                    require("../../images/banners/" +
                                      item.logo),
                                    "default"
                                  ),
                                  height: "48px",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "fs-32 font-weight-bold mb-6 oxygen",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.title) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              item.description
                                ? _c("p", { staticClass: "fs-20 mb-6" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.description) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "list-none pl-0" },
                                _vm._l(item.list, function (item, key) {
                                  return _c(
                                    "li",
                                    { key: key },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        mdi-check\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-col", [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item) +
                                                  "\n                                        "
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-6",
                                  attrs: {
                                    block: _vm.isMobilePort,
                                    color: "primary",
                                    large: "",
                                    href: _vm.getRoute(item.button.link),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.button.text) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("img", {
                              staticStyle: { "max-width": "100%" },
                              attrs: {
                                alt: "",
                                src: _vm.asset(
                                  require(`../../images/premium_page/${item.image}`),
                                  "default"
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container py-10" },
            [
              _c("sub-main-heading", {
                attrs: {
                  "sub-heading": "In the office or out in the field",
                  "main-heading":
                    "Codes and standards access where you need it most",
                },
              }),
              _vm._v(" "),
              _c(
                "v-row",
                _vm._l(_vm.codeAccessCards, function (item, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      attrs: {
                        cols: "12",
                        sm: "4",
                        "offset-sm": key % 2 === 0 ? 2 : 0,
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "fill-height",
                          attrs: { elevation: "5" },
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              item.image
                                ? _c("v-img", {
                                    staticClass: "mb-2",
                                    attrs: {
                                      src: _vm.asset(
                                        require("../../images/" + item.image),
                                        "default"
                                      ),
                                      height: "300",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass: "mb-4 fs-20 font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.title) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "fs-16" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.description) +
                                    "\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  item.button
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            large: "",
                                            href: _vm.getRoute(
                                              item.button.link
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.button.text) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.mobile
                                    ? [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              dense: "",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c("img", {
                                                  staticClass: "cp",
                                                  attrs: {
                                                    height: "44px",
                                                    alt: "Get it on Google Play",
                                                    src: _vm.asset(
                                                      require("../../images/icons/app_store_google.webp")
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.appClick()
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c("img", {
                                                  staticClass: "cp",
                                                  attrs: {
                                                    height: "44px",
                                                    alt: "Download on the App Store",
                                                    src: _vm.asset(
                                                      require("../../images/icons/app_store_apple.webp")
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.appClick(true)
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container my-16", attrs: { id: "priceBlock" } },
        [
          _c("sub-main-heading", {
            attrs: {
              "sub-heading": "Make Digital Codes work for you",
              "main-heading": "Flexible subscription options to fit every need",
            },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            _vm._l(_vm.priceBlocks, function (item, key) {
              return _c(
                "v-col",
                { key: key },
                [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "fill-height",
                      style: item.complete
                        ? `border: 3px solid ${_vm.$vuetify.theme.themes.light.accent2}`
                        : `border: 1px solid ${_vm.$vuetify.theme.themes.light.primary}`,
                      attrs: { rounded: "" },
                    },
                    [
                      _c("v-card-text", [
                        _c(
                          "h2",
                          { staticClass: "fs-24 font-weight-medium mb-4" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.title) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "fs-20 font-weight-medium mb-4" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.description) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "fs-16 grey--text text--darken-1" },
                          [
                            _vm._v(
                              "\n                            Starting at\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "fs-32" }, [
                          _vm._v(
                            "\n                            $" +
                              _vm._s(item.price) +
                              "/month\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "list-none pl-0" },
                          _vm._l(item.list, function (item, key) {
                            return _c(
                              "li",
                              { key: key },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "auto" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        mdi-check\n                                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-col", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 fs-16 font-weight-medium",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            item.button
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-6",
                                    attrs: {
                                      color: item.complete
                                        ? "accent2"
                                        : "primary",
                                      large: "",
                                      href: item.button.link,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.button.text) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "fs-20 text-center mt-8 font-weight-medium" },
            [
              _vm._v(
                "\n            Empower collaboration within your organization with "
              ),
              _c(
                "a",
                {
                  staticClass: "text-decoration-underline",
                  attrs: {
                    href: _vm.getRoute("concurrent-access"),
                    title: "Premium for Teams",
                  },
                },
                [_vm._v("Premium for Teams")]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5 py-6" },
        [_c("customer-testimonials")],
        1
      ),
      _vm._v(" "),
      _c("hero-banner-new", {
        staticClass: "content-container",
        attrs: {
          title: "Try Premium Complete FREE for 14 days",
          subtitle: "No credit card required when you sign up",
        },
        scopedSlots: _vm._u([
          {
            key: "button",
            fn: function () {
              return [
                _c("start-trial-button", {
                  staticClass: "mr-3",
                  attrs: { block: _vm.isMobilePort, color: "accent2" },
                }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    class: { "mt-4": _vm.isMobilePort },
                    attrs: {
                      block: _vm.isMobilePort,
                      large: "",
                      color: "primary",
                      outlined: "",
                      href: "#priceBlock",
                    },
                  },
                  [_vm._v("\n                Subscribe Now\n            ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "image",
            fn: function () {
              return [
                _c("img", {
                  staticStyle: { "max-width": "100%" },
                  attrs: {
                    alt: "",
                    src: _vm.asset(
                      require("../../images/pc_computer.webp"),
                      "default"
                    ),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }