<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="dashboard-tags">
        <breadcrumb title="Manage Project Tags"></breadcrumb>
        <v-container>
            <v-row justify-xl="center">
                <v-col xl="10">
                    <v-card>
                        <v-toolbar
                            flat
                            color="primary"
                            dark>
                            <v-toolbar-title>
                                <v-icon>comment</v-icon> Project Tags
                            </v-toolbar-title>
                            <template v-if="!isMobilePort">
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="accent2"
                                    @click="openModal(null)">
                                    Add Project Tag
                                </v-btn>
                            </template>
                        </v-toolbar>
                        <v-card-text class="pa-0">
                            <template>
                                <v-container>
                                    <v-row
                                        dense
                                        justify="center"
                                        v-if="isMobilePort">
                                        <v-col class="text-center">
                                            <v-btn
                                                dark
                                                color="accent2"
                                                block
                                                @click="openModal(null)">
                                                Add Project Tag
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-data-table
                                    class="ma-0"
                                    must-sort
                                    :headers="headers"
                                    :items="tags"
                                    :loading="loading"
                                    :items-per-page="itemsPerPage[0]"
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPage
                                    }">
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>
                                                {{item.tag}}
                                            </td>
                                            <td>
                                                <v-avatar
                                                    class="mr-4"
                                                    :color="'#'+item.color"
                                                    size="15">
                                                </v-avatar>
                                                <template v-if="!isMobilePort">#{{item.color}}</template>
                                            </td>
                                            <td
                                                class="text-right area-field-focus"
                                                v-if="isTagOwner(item.user)">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            class="mr-1"
                                                            color="grey lighten-4"
                                                            fab
                                                            x-small
                                                            dark
                                                            elevation="1"
                                                            v-on="on"
                                                            @click="openModal(item)"
                                                            :class="{'float-right': isMobilePort}">
                                                            <v-icon color="grey darken-1">edit</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Edit {{item.tag}}</span>
                                                </v-tooltip>
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            class="ml-1"
                                                            color="grey lighten-4"
                                                            fab
                                                            x-small
                                                            dark
                                                            elevation="1"
                                                            v-on="on"
                                                            @click="openConfirmModal(item)"
                                                            :class="{'float-right': isMobilePort}">
                                                            <v-icon color="grey darken-1">delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Delete {{item.tag}}</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-else>
                                                View Only
                                            </td>
                                        </tr>
                                    </template>
                                    <template slot="no-results"></template>
                                </v-data-table>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <edit-modal></edit-modal>
        <v-dialog
            v-model="dialogConfirm"
            width="400"
            persistent>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-expand-transition>
                            <v-row v-show="error">
                                <v-col>
                                    <v-alert
                                        class="mb-0"
                                        type="error"
                                        dense>
                                        {{ error }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-expand-transition>
                        <v-row>
                            <v-col>
                                <p>You are about to remove all annotations and bookmarks associated with the project tag <span class="font-weight-bold">"{{tagName}}"</span>.  This action cannot be undone.</p>
                                <p>Are you sure you wish to proceed?</p>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                            justify="center">
                            <v-col sm="auto">
                                <v-btn
                                    color="error"
                                    @click="closeConfirmModal">
                                    Cancel
                                </v-btn>
                            </v-col>
                            <v-col sm="auto">
                                <v-btn
                                    color="primary"
                                    @click="deleteTag">
                                    <span v-if="!saving">
                                        Confirm
                                    </span>
                                    <v-progress-circular
                                        v-else
                                        :size="20"
                                        indeterminate
                                        color="white">
                                    </v-progress-circular>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '../../content/partials/page_titles';
    import EditModal from './dialogs/editTag';
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data() {
            return {
                loading: true,
                tagId: 0,
                tags: [],
                error: '',
                tagName: '',
                headers: [
                    {text: 'Tag Name', align: 'start', value: 'tag', width: '45%'},
                    {text: 'Color', align: 'start', value: 'color', width: '45%'},
                    {text: '', align: 'right', value: null, sortable: false}
                ],
                itemsPerPage: [10, 20, 30, 50],
                dialogConfirm: false,
                saving: false,
                swr: 'Something went wrong.  Please contact us.',
            }
        },
        components: {
            Breadcrumb,
            EditModal
        },
        mixins: [
            Mobile
        ],
        methods: {
            getTags() {
                this.loading = true;
                this.$http.get(Routing.generate('get_user_tags'))
                    .then(response => {
                        this.resetTag();
                        this.tags = response.data;
                        this.loading = false;
                    });
            },
            deleteTag() {
                if (!_.isEmpty(this.tagName)) {
                    this.saving = true;
                    this.error = '';
                    this.$http.post(Routing.generate('remove_user_tag'), {id: this.tagId})
                        .then(response => {
                            if (response.data) {
                                this.dialogConfirm = this.saving = false;
                                this.resetTag();
                                this.getTags();
                            } else {
                                this.error = this.swr;
                                this.saving = false;
                            }
                        });
                } else {
                    this.error = this.swr;
                }
            },
            openModal(data) {
                let tagData = null;
                if (!_.isNull(data))
                    tagData = {
                        id: data.id,
                        name: data.tag,
                        color: '#'+data.color
                    };
                EventBus.fire('show-tag-edit-modal', tagData);
            },
            openConfirmModal(data) {
                this.tagName = data.tag;
                this.tagId = data.id
                this.dialogConfirm = true;
            },
            closeConfirmModal() {
                this.dialogConfirm = this.saving = false;
                this.resetTag();
            },
            resetTag() {
                this.tagId = 0;
                this.tagName = '';
            },
            isTagOwner(userId) {
                return userId == this.$store.getters.getUser.id;
            }
        },
        created() {
            this.getTags();
        },
        mounted() {
            EventBus.listen('tag-edit-modal-callback', () => this.getTags());
        }
    }
</script>

<style lang="scss">
    #dashboard-tags {
        table {
            margin: 0;
        }
    }
</style>
