<template>
    <div>
        <hero-banner-new
            class="content-container"
            title="Go digital, work smarter with Digital Codes Premium"
            subtitle="Streamline code compliance with enhanced tools and exclusive features">
            <template v-slot:button>
                <v-btn
                    :block="isMobilePort"
                    elevation="0"
                    class="font-weight-bold"
                    :class="{'mt-4': isMobilePort}"
                    large
                    color="accent2"
                    href="#priceBlock">
                    Subscribe
                </v-btn>
                <start-trial-button
                    :block="isMobilePort"
                    class="mr-3"
                    outlined
                    color="primary">
                </start-trial-button>
            </template>
            <template v-slot:image>
                <img
                    style="max-width: 100%"
                    alt=""
                    :src="asset(require('../../images/pc_computer.webp'), 'default')"/>
            </template>
        </hero-banner-new>
        <div class="grey lighten-5">
            <v-container class="content-container pt-8">
                <v-row justify="center">
                    <v-col
                        v-for="(item, key) in icons"
                        :key="key"
                        cols="auto"
                        v-if="!isMobilePort || (isMobilePort && key <= 2)">
                        <img
                            width="72px"
                            alt=""
                            :src="asset(require(`../../images/icons/titles/${item}`), 'default')"/>
                    </v-col>
                </v-row>
                <sub-main-heading
                    sub-heading="Largest and most trusted online database"
                    main-heading="Building codes, standards and more exclusive ICC content">
                </sub-main-heading>
                <v-row class="mb-6">
                    <v-col
                        cols="12"
                        sm="4"
                        v-for="(item, key) in cards"
                        :key="key"
                        :offset-sm="key % 2 === 0 ? 2: 0">
                        <v-card elevation="5">
                            <v-card-text>
                                <p
                                    v-html="item"
                                    class="mb-0 text-center fs-16">
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="content-container mt-16 mb-10">
            <sub-main-heading
                sub-heading="Unlock compliance research benefits"
                main-heading="Premium tools to ensure you understand the code">
            </sub-main-heading>
            <v-row>
                <v-col
                    :order="1"
                    :order-sm="0"
                    cols="12"
                    sm="6">
                    <v-row
                        v-for="(item, key) in premiumTools"
                        :key="key"
                        dense>
                        <v-col cols="auto">
                            <v-icon
                                color="accent2">
                                {{item.icon}}
                            </v-icon>
                        </v-col>
                        <v-col>
                            <p class="font-weight-medium mb-1">
                                {{item.title}}
                            </p>
                            <p style="line-height: 20px;">
                                {{item.description}}
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <img
                        style="max-width: 100%"
                        alt=""
                        :src="asset(require(`../../images/premium_page/premium_tools.webp`), 'default')"/>
                </v-col>
            </v-row>
        </v-container>
        <template v-for="(item, key) in heroBlocks">
            <div
                v-if="item.active"
                :key="key"
                :class="key % 2 === 0 ? 'grey lighten-5' : ''">
                <v-container class="content-container py-8">
                    <v-row align="center">
                        <v-col
                            cols="12"
                            sm="6"
                            :order="1"
                            :order-sm="key % 2 === 0 ? 1 : 0">
                            <img
                                class="mb-4"
                                alt=""
                                :src="asset(require('../../images/banners/'+item.logo), 'default')"
                                height="48px"/>
                            <h2 class="fs-32 font-weight-bold mb-6 oxygen">
                                {{item.title}}
                            </h2>
                            <p
                                v-if="item.description"
                                class="fs-20 mb-6">
                                {{item.description}}
                            </p>
                            <ul class="list-none pl-0">
                                <li v-for="(item, key) in item.list"
                                :key="key">
                                    <v-row dense>
                                        <v-col cols="auto">
                                            <v-icon
                                            small
                                            color="primary">
                                            mdi-check
                                            </v-icon>
                                        </v-col>
                                        <v-col>
                                            <p class="mb-0">
                                                {{item}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </li>
                            </ul>
                            <v-btn
                                :block="isMobilePort"
                                class="mt-6"
                                color="primary"
                                large
                                :href="getRoute(item.button.link)">
                                {{item.button.text}}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <img
                                style="max-width: 100%"
                                alt=""
                                :src="asset(require(`../../images/premium_page/${item.image}`), 'default')"/>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </template>
        <div class="grey lighten-5">
            <v-container class="content-container py-10">
                <sub-main-heading
                    sub-heading="In the office or out in the field"
                    main-heading="Codes and standards access where you need it most">
                </sub-main-heading>
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                        v-for="(item, key) in codeAccessCards"
                        :key="key"
                        :offset-sm="key % 2 === 0 ? 2: 0">
                        <v-card
                            class="fill-height"
                            elevation="5">
                            <v-card-text>
                                <v-img
                                    v-if="item.image"
                                    class="mb-2"
                                    :src="asset(require('../../images/'+item.image), 'default')"
                                    height="300">
                                </v-img>
                                <h3 class="mb-4 fs-20 font-weight-medium">
                                    {{item.title}}
                                </h3>
                                <p
                                    class="fs-16">
                                    {{item.description}}
                                </p>
                                <div class="text-center">
                                    <v-btn
                                        v-if="item.button"
                                        color="primary"
                                        large
                                        :href="getRoute(item.button.link)">
                                        {{item.button.text}}
                                    </v-btn>
                                    <template v-if="item.mobile">
                                        <v-row
                                            dense
                                            justify="center">
                                            <v-col cols="auto">
                                                <img
                                                    height="44px"
                                                    class="cp"
                                                    alt="Get it on Google Play"
                                                    @click="appClick()"
                                                    :src="asset(require('../../images/icons/app_store_google.webp'))"/>
                                            </v-col>
                                            <v-col cols="auto">
                                                <img
                                                    height="44px"
                                                    class="cp"
                                                    alt="Download on the App Store"
                                                    @click="appClick(true)"
                                                    :src="asset(require('../../images/icons/app_store_apple.webp'))"/>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container
            id="priceBlock"
            class="content-container my-16">
            <sub-main-heading
                sub-heading="Make Digital Codes work for you"
                main-heading="Flexible subscription options to fit every need">
            </sub-main-heading>
            <v-row>
                <v-col
                    v-for="(item, key) in priceBlocks"
                    :key="key">
                    <v-sheet
                        class="fill-height"
                        rounded
                        :style="item.complete ? `border: 3px solid ${$vuetify.theme.themes.light.accent2}` : `border: 1px solid ${$vuetify.theme.themes.light.primary}`">
                        <v-card-text>
                            <h2 class="fs-24 font-weight-medium mb-4">
                                {{item.title}}
                            </h2>
                            <p class="fs-20 font-weight-medium mb-4">
                                {{item.description}}
                            </p>
                            <p class="fs-16 grey--text text--darken-1">
                                Starting at
                            </p>
                            <p class="fs-32">
                                ${{item.price}}/month
                            </p>
                            <ul class="list-none pl-0">
                                <li v-for="(item, key) in item.list"
                                :key="key">
                                    <v-row dense>
                                        <v-col cols="auto">
                                            <v-icon
                                            small
                                            color="primary">
                                            mdi-check
                                            </v-icon>
                                        </v-col>
                                        <v-col>
                                            <p class="mb-0 fs-16 font-weight-medium">
                                            {{item}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </li>
                            </ul>
                            <div class="text-center">
                                <v-btn
                                    class="mt-6"
                                    v-if="item.button"
                                    :color="item.complete ? 'accent2' : 'primary'"
                                    large
                                    :href="item.button.link">
                                    {{item.button.text}}
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-sheet>
                </v-col>
            </v-row>
            <p class="fs-20 text-center mt-8 font-weight-medium">
                Empower collaboration within your organization with <a class="text-decoration-underline" :href="getRoute('concurrent-access')" title="Premium for Teams">Premium for Teams</a>
            </p>
        </v-container>
        <div class="grey lighten-5 py-6">
            <customer-testimonials>
            </customer-testimonials>
        </div>
        <hero-banner-new
            class="content-container"
            title="Try Premium Complete FREE for 14 days"
            subtitle="No credit card required when you sign up">
            <template v-slot:button>
                <start-trial-button
                    :block="isMobilePort"
                    class="mr-3"
                    color="accent2">
                </start-trial-button>
                <v-btn
                    :block="isMobilePort"
                    :class="{'mt-4': isMobilePort}"
                    large
                    color="primary"
                    outlined
                    href="#priceBlock">
                    Subscribe Now
                </v-btn>
            </template>
            <template v-slot:image>
                <img
                    style="max-width: 100%"
                    alt=""
                    :src="asset(require('../../images/pc_computer.webp'), 'default')"/>
            </template>
        </hero-banner-new>
    </div>
</template>

<script>
    import HeroBannerNew from "../../components/banners/HeroBannerNew.vue";
    import StartTrialButton from "../../components/buttons/StartFreeTrial.vue";
    import {AppParameters} from "../../AppParameters";
    import CustomerTestimonials from "../../components/homepage/CustomerTestimonials.vue";
    import Mobile from "../../components/inc/mixins/mobile";
    import SubMainHeading from "../../components/headers/SubMain.vue";

    export default {
        components: {SubMainHeading, CustomerTestimonials, StartTrialButton, HeroBannerNew},
        mixins: [Mobile],
        data() {
            return {
                icons: [
                    'RGB_IBC.svg',
                    'RGB_IRC.svg',
                    'RGB_IFC.svg',
                    'RGB_IPC.svg',
                    'RGB_IMC.svg',
                    'RGB_IECC.svg',
                    'RGB_IEBC.svg',
                    'RGB_IFGC.svg',
                    'RGB_IPMC.svg',
                    'RGB_IPSDC.svg',
                    'RGB_IWUIC.svg',
                    'RGB_IZC.svg',
                ],
                cards: [
                    '<span class="font-weight-medium">International Codes (I-Codes)</span>, the most widely accepted comprehensive set of model codes',
                    '<span class="font-weight-medium">Custom state and local codes</span> with integrated amendments, errata and supplements',
                    '<span class="font-weight-medium">Standards</span> referenced throughout the codes from constantly expanding offering of Standards Development Organizations',
                    '<span class="font-weight-medium">Code Change Process</span> publications with detailed updates including <span class="font-weight-medium">Revision History</span> and <span class="font-weight-medium">Significant Changes</span>',
                    '<span class="font-weight-medium">Code Commentaries</span> with comprehensive practical explanations for each code section',
                    '<span class="font-weight-medium">Training Resource series</span> including <span class="font-weight-medium">Study Companions, Essentials, FlashCards</span> and more'
                ],
                premiumTools: [
                    {
                        icon: 'mdi-lightbulb-outline',
                        title: 'Premium Insights',
                        description: 'Includes Code Change Details, Hearing Videos, Key Changes, Code Interpretations, Commentaries and more'
                    },
                    {
                        icon: 'mdi-magnify',
                        title: 'Advanced Search',
                        description: 'Use multiple filters to narrow down search results and identify codes sections more accurately'
                    },
                    {
                        icon: 'mdi-format-text-rotation-none',
                        title: 'Color Coded Text',
                        description: 'Including technical changes and amendments at the local, city, and state levels'
                    },
                    {
                        icon: 'mdi-bookmark-outline',
                        title: 'Bookmarks and Notes',
                        description: 'Organize thoughts by bookmarking sections and adding notes that you can refer to anytime'
                    },
                    {
                        icon: 'mdi-content-copy',
                        title: 'Content Controls',
                        description: 'Copy, paste and print sections of codes'
                    }
                ],
                heroBlocks: [
                    {
                        active: AppParameters.enable_premium_answers_page,
                        logo: 'premium_answers.png',
                        title: 'Access a database of solutions with Premium Answers',
                        description: 'An expanding database of compliance questions and their interpretations included in all Premium subscriptions.',
                        list: [
                            'Filter or search by topics, titles and keywords',
                            'Links to relevant sections across the code contents',
                            'New questions and answers added every month'
                        ],
                        button: {
                            text: 'View Premium Answers',
                            link: 'premium_answers'
                        },
                        image: 'premium_answers.webp',
                    },
                    {
                        active: AppParameters.ai_navigator_enabled,
                        logo: 'ai_navigator.png',
                        title: 'Understand codes faster with ICC AI Navigator Beta',
                        list: [
                            'Navigate I-Codes with the help of powerful AI',
                            'Trained and tested by ICC experts',
                            'Included with all Premium subscriptions'
                        ],
                        button: {
                            text: 'Learn About AI Navigator',
                            link: 'features_ai_navigator'
                        },
                        image: 'ai_navigator.webp',
                    }
                ],
                codeAccessCards: [
                    {
                        image: 'revit/app_docked.webp',
                        title: 'ICC Digital Codes Premium Revit® Add-In',
                        description: 'Use building codes and standards directly within your BIM application',
                        button: {
                            text: 'Get Revit Add-in',
                            link: 'revit'
                        }
                    },
                    {
                        image: 'pc_page/pc_mobile.png',
                        title: 'Digital Codes Premium Mobile App',
                        description: 'Access titles offline with the Digital Codes Premium mobile app for iOS and Android',
                        mobile: true
                    }
                ],
                priceBlocks: [
                    {
                        title: 'Premium Singles',
                        description: 'Subscription to only the individual title or titles needed most',
                        price: AppParameters.single_monthly_price,
                        list: [
                            'All premium features included with subscribed title',
                            'Monthly, Annual and 3-Year renewable durations',
                            'Volume and ICC member discounts available'
                        ],
                        button: {
                            text: 'View Singles',
                            link: this.getRoute('codes')
                        }
                    },
                    {
                        title: 'Premium Collections',
                        description: 'Bundled set of related titles for a given topic or cycle',
                        price: AppParameters.collection_monthly_price,
                        list: [
                            'All premium features included with subscribed title',
                            'Monthly, Annual and 3-Year renewable durations',
                            'Volume and ICC member discounts available'
                        ],
                        button: {
                            text: 'View Available Collections',
                            link: this.getRoute('codes_category', {category: 'collections'})
                        }
                    },
                    {
                        title: 'Premium Complete',
                        description: `Largest collection of over ${this.premiumCompleteTitlesCount()} titles and features all in one`,
                        price: AppParameters.complete_monthly_price,
                        list: [
                            'All premium features included with subscribed title',
                            'Monthly, Annual and 3-Year renewable durations',
                            'Volume and ICC member discounts available'
                        ],
                        button: {
                            text: 'View Premium Complete',
                            link: 'collections/premium-complete'
                        },
                        complete: true
                    }
                ]
            }
        }
    }
</script>
