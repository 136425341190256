var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("premium-tool-header", {
    attrs: {
      title: "Premium Answers",
      "sub-title": "Real questions answered by our expert team",
    },
    scopedSlots: _vm._u([
      {
        key: "avatar",
        fn: function () {
          return [
            _c("img", {
              attrs: {
                width: "30",
                height: "102",
                src: _vm.asset(
                  require("../../images/icons/premium_answers_icon.svg")
                ),
                alt: "FEEDBACK",
                title: "Feedback",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "tabs",
        fn: function () {
          return [
            _vm.quickAskEnabled
              ? _c(
                  "v-tabs",
                  {
                    staticClass: "white",
                    attrs: { "align-tabs": "left" },
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _c(
                      "v-tab",
                      {
                        staticClass: "font-weight-bold",
                        class:
                          _vm.activeTab === 0
                            ? "white--text"
                            : "grey--darken-1",
                        attrs: { value: "0" },
                        on: { click: _vm.goToDatabase },
                      },
                      [_vm._v("\n                Q&A Database\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tab",
                      {
                        staticClass: "font-weight-bold px-4",
                        class:
                          _vm.activeTab === 1
                            ? "white--text"
                            : "grey--darken-1",
                        attrs: { value: "1" },
                        on: { click: _vm.goToQuickConsult },
                      },
                      [_vm._v("\n                Quick Consult\n            ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }